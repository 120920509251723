import logoBlackText from './onezee-logo-black-text.svg';
import logoWhiteText from './onezee-logo-white-text.svg';

type LogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  variant?: 'blackText' | 'whiteText';
};
export function LogoWebsite({
  className,
  variant = 'blackText',
  ...props
}: LogoProps) {
  const logoSrc = variant === 'whiteText' ? logoWhiteText : logoBlackText;
  return <img {...props} src={logoSrc} alt="Onezee" className={className} />;
}
